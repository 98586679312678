import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AccountService } from './services/account.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from './shared/shared.module';
import { MatCardModule } from '@angular/material/card';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';
import { MaterialLayoutModule } from 'app/layout/layouts/horizontal/material/material.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FuseHighlightModule } from '@fuse/components/highlight';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseScrollResetModule } from '@fuse/directives/scroll-reset';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { NgxWhastappButtonModule } from 'ngx-whatsapp-button';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { ShareModule } from 'ngx-sharebuttons';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        //NoopAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})

        , HttpClientModule
        // ,NgxWhastappButtonModule
        // ,FontAwesomeModule
        // ,ShareModule
        //,HttpModule

        ,FormsModule
        ,MatButtonModule
        ,MatIconModule
        ,MatToolbarModule
        ,MatProgressSpinnerModule

        ,MatCheckboxModule
        ,MatFormFieldModule
        ,MatInputModule
        ,MatStepperModule
         ,MatDatepickerModule
        ,MatNativeDateModule
        ,MatRippleModule
         ,MatRadioModule
        ,MatSelectModule
        ,MatOptionModule
        ,FuseCardModule
        ,FuseAlertModule
        ,MaterialLayoutModule
        ,MatSidenavModule
        //,MatListModule
        ,MatGridListModule
        ,ReactiveFormsModule
        ,FuseHighlightModule
       , FuseNavigationModule
        ,FuseScrollResetModule
        ,MatCardModule
        ,TranslocoModule

        ,SharedModule
        ,FormsModule
        // ,TranslocoModule.forRoot({
        //     loader: {
        //         provide: TranslateLoader,
        //         useFactory: (createTranslateLoader),
        //         deps: [HttpClient]
        //     }
        // })
    ],
    providers: [
        AccountService,
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        }
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
